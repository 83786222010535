import { Controller } from '@hotwired/stimulus';
import { application } from 'src/app';
import { toggleClass, find, getParent } from '@fretadao/f-js-dom';
import './component.scss';

application.register(
  'navbar',
  class extends Controller {
    static targets = ['iconMenu', 'iconCross'];
    static classes = ['dropdownMenuActive', 'dropdownButtonOpen'];

    changeIcon () {
      this.iconMenuTargets.forEach(icon => toggleClass(icon, 'hidden'));
      toggleClass(this.iconCrossTarget, 'hidden');
    }

    toggleDropdown ({target}) {
      const menuEl = find('.Navbar-dropdownMenu', getParent(target));

      toggleClass(target, this.dropdownButtonOpenClass);
      toggleClass(menuEl, this.dropdownMenuActiveClass);
    }
  }
);
