import {Controller} from '@hotwired/stimulus';
import {application} from 'src/app';
import {find, removeClass, addClass, findAll} from '@fretadao/f-js-dom';
import './component.scss';

application.register(
  'carousel',
  class extends Controller {
    static targets = ['navigationButton'];
    static values = { currentSlide: Number, disableAutoplay: Boolean, prefix: String, timeout: Number };

    slideClass = 'Carousel-slide';
    nextButtonClass = 'Carousel-nextSlide';
    prevButtonClass = 'Carousel-previousSlide';
    navigationClass = 'Carousel-manualNavigation';
    activeButtonClass = 'Carousel-navigationButton--active';

    slides = findAll(`.${this.slideClass}`, this.element);
    slideCount = this.slides.length;

    connect() {
      this.setInitialClasses();

      if (!this.disableAutoplayValue) {
        this.setCarouselTimeout();
      }
    }

    setCarouselTimeout() {
      if (this.timeoutValue === 0) {
        this.timeoutValue = 10000;
      }

      setInterval(() => {
        this.moveNext();
      }, this.timeoutValue);
    }

    setInitialClasses() {
      addClass(this.slides[this.slideCount - 1], 'prev');
      addClass(this.slides[0], 'active');
      addClass(this.slides[1], 'next');
    }

    moveNext() {
      this.currentSlideValue = this.findNextOf(this.currentSlideValue);
      this.changeSlide(this.currentSlideValue);
    }

    movePrev() {
      this.currentSlideValue = this.findPreviousOf(this.currentSlideValue);
      this.changeSlide(this.currentSlideValue);
    }

    findNextOf(baseIndex) {
      return (baseIndex + 1) % this.slideCount;
    }

    findPreviousOf(baseIndex) {
      return (baseIndex + (this.slideCount - 1)) % this.slideCount;
    }

    changeSlide(target) {
      if (this.slideCount === 1) return;

      const next = find('.next', this.element);
      const prev = find('.prev', this.element);
      const current = find('.active', this.element);
      const newNext = this.findNextOf(target);
      const newPrevious = this.findPreviousOf(target);

      this.reassignClasses(
        {nextSlide: next, previousSlide: prev, currentSlide: current},
        {nextSlide: newNext, currentSlide: target, previousSlide: newPrevious}
      );

      if(find(`.${this.navigationClass}`, this.element) !== null) {
        this.highlightNavBtn(target);
      }
    }

    reassignClasses(oldElements, newElements) {
      removeClass(oldElements.nextSlide, 'next');
      removeClass(oldElements.previousSlide, 'prev');
      removeClass(oldElements.currentSlide, 'active');

      addClass(find(`#${this.prefixValue}-${newElements.nextSlide}`), 'next');
      addClass(find(`#${this.prefixValue}-${newElements.previousSlide}`), 'prev');
      addClass(find(`#${this.prefixValue}-${newElements.currentSlide}`), 'active');
    }

    changeTo(event) {
      const target = parseInt(event.target.dataset.value);

      this.currentSlideValue = target;
      this.highlightNavBtn(target);
      this.changeSlide(target);
    }

    highlightNavBtn(targetValue) {
      const currentHighlighted = find(`.${this.activeButtonClass}`, this.element);
      const newHighlighted = find(`[data-value="${targetValue}"]`, this.element);

      removeClass(currentHighlighted, this.activeButtonClass);
      addClass(newHighlighted, this.activeButtonClass);
    }
  }
);
