import { Controller } from '@hotwired/stimulus';
import { application } from 'src/app';
import { addClass, removeClass } from '@fretadao/f-js-dom';
import { isBlank } from '@fretadao/f-js-utils';
import Cookies from 'js-cookie'

import './component.scss';

application.register('cookies-bar', class extends Controller {
  connect () {
    const elemClass = isBlank(Cookies.get('allow_cookies')) ? 'hidden' : 'Flex';
    removeClass(this.element, elemClass);
  }

  agreeToCookies () {
    // the vendor inside the vendors folder is not recognized inside the webpacker pipeline,
    // requiring us to add the same package on webpacker
    event.preventDefault();
    this.setCookie('yes');
    window.location.reload();
  }

  agreeOnlyEssentialsCookies () {
    event.preventDefault();
    this.setCookie('no');
    removeClass(this.element, 'Flex');
    addClass(this.element, 'hidden');
  }

  setCookie (answer) {
    Cookies.set('allow_cookies', answer, { expires: 365 });
  }
});
